

.navbar .dropdown-menu .dropdown-item:hover .nav-link{
    color :#fff;
}

.text-primary{
    color:$primary !important; 
}

.text-secondary{
    color:$branddefault !important;
}
.text-success{
    color:#0dd97b !important;
}
.disabled-link{
    cursor: not-allowed !important;
    opacity: 0.5;
    text-decoration: none;
}
.text-danger{
    color:$danger !important; 
}
.text-missed{
    color:lighten($danger, 0.50)
}
.fw-bold{
    font-weight: 500 !important;
}
.fw-bolder{
    font-weight: 700 !important;
}
.form-check-input{
    border-color: $branddefault !important;
    margin-top: 0px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    cursor: pointer;
    label{
        cursor: pointer;
    }
    @media only screen and (max-width: 575.98px) {
        width: 18px;
        height: 18px;
    }
}
.form-check-input:checked {
    background-color:$white;
    border-color: $primary !important;
   
    height: 20px;
    width: 20px;
    @media only screen and (max-width: 575.98px) {
        width: 18px;
        height: 18px;
    }
}
.form-check-input[type=checkbox]{

    border-radius: 0px !important;
 
}
.form-check-input:checked[type=radio] {
    background-size: 12px;
    // background-image: url('../../assets/images/Dot.svg');
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12' height='12' viewBox='0 0 12 12'%3E%3Cdefs%3E%3CclipPath id='clip-Dot'%3E%3Crect width='12' height='12'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Dot' clip-path='url(%23clip-Dot)'%3E%3Cpath id='Icon_ionic-md-radio-button-on' data-name='Icon ionic-md-radio-button-on' d='M23.82,18.375a5.445,5.445,0,1,0,5.445,5.445A5.461,5.461,0,0,0,23.82,18.375Z' transform='translate(29.82 -17.82) rotate(90)' fill='%23"+ $svg-primary +"'/%3E%3C/g%3E%3C/svg%3E");

}
.form-check-input:checked[type=checkbox]{
    background-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='18' viewBox='0 0 24 18'%3E%3Cdefs%3E%3CclipPath id='clip-Check'%3E%3Crect width='24' height='18'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Check' clip-path='url(%23clip-Check)'%3E%3Cpath id='Path_12237' data-name='Path 12237' d='M-9056.223,2783.055l-1.865,2.091,6.11,6.171,11.757-11.67-2.111-1.9-9.656,9.841Z' transform='translate(9061.11 -2775.534)' fill='%23"+ $svg-primary +"'/%3E%3C/g%3E%3C/svg%3E");
    
}
.form-check-input:focus{
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);


}

.breadcrumb{
    padding: 20px 0px !important;
    align-items: center;
    margin: 0px;
    .breadcrumb-item {
        
        &::before{
            content: '' !important;
        }
        &::after{
            content: '\E80A';
            font-family: "mhc-icon";
            font-weight: normal;
            font-style: normal;
            font-size: 0.875rem;
            padding-left: 0.5rem;
        }
        &:last-child::after{
            content: ''; 
        }
        span{
            font-size: 0.875rem;  
        }
        a{
            color: lighten($default, 20%);
            text-decoration: none;
            font-weight: 500;
            font-size: 0.875rem;  
            @media only screen and (max-width: 767.98px) {
                font-size: 13px;   
            }
            @media only screen and (max-width: 575.98px) {
                font-size: 12px;   
            }
            cursor: pointer;
            &:active, &:hover{
                color: darken($default, 10%);
            }
        }
        &.active{
            color: $default;
            font-weight: 500;
            font-size: 0.875rem; 
            @media only screen and (max-width: 767.98px) {
                font-size: 0.813rem;   
            }
            @media only screen and (max-width: 575.98px) {
                font-size: 0.75rem;   
            }  
        }
       
    }
}
.breadcrumb-item+.breadcrumb-item::before{
    padding-right: 0rem !important;
}
.form-floating{
    .form-control {
        padding-left: 14px;
        height: calc(1.6em + .75rem + 2px) !important;
    }
    .form-select{
        padding-left: 14px;
        height: calc(1.6em + .75rem + 2px) !important;
        padding-top: 8px;
        padding-bottom: 5px;
    }
  label{
    padding: 0px !important;
    font-size: 0.938rem;
    margin-left: 7px;
    height: 20px;
    top: 18px !important;
    line-height: 0;
    transition: 0.3s all  ease-out;
  }
}
.form-floating>.form-control:read-only:not(:placeholder-shown)~label, .form-floating>.form-select:disabled~label{
background: #fcfcfc;
}
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label{
    transform: scale(.85) translateY(-1.5rem) translateX(0.15rem) !important;
    background: $white;
    opacity: 1;
    color: $lable-color;
    height: 10px;
}
.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown){
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.form-select{
    height: calc(1.6em + .75rem + 2px) !important;
    padding: 0.575rem 2.25rem 0.375rem 0.75rem;
}
.accordion-button:focus{
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
.specialitiestabs{
  
    .accordion-item{
        border:solid 0px $white;
      
        align-items: flex-start;
        .accordion-header{
            font-size: 1rem;
          a{
              color: $default;
              font-weight: 600;
              text-decoration: none;
              width: 100%;
          }
            border-bottom: solid 1px $light-border;
            img{
                width: auto;
                height: 30px;
                margin-right: 10px;
            }
        
            .accordion-button{
                padding: 7px 10px 7px 0px;
                color: $default;
                font-weight: 500;
                &:after{
                    // background-image:url("../../assets/images/ionic-ios-arrow-back.svg");
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.656' height='10.095' viewBox='0 0 17.656 10.095'%3E%3Cpath data-name='Icon ionic-ios-arrow-back' d='M7.052 8.826.371 2.15A1.262 1.262 0 0 1 2.158.369l7.569 7.563a1.259 1.259 0 0 1 .037 1.74l-7.6 7.616a1.262 1.262 0 0 1-1.788-1.782z' transform='rotate(90 8.828 8.828)' style='fill:%23666'/%3E%3C/svg%3E");
                    background-size: 13px;
                    width: 16px;
                    height: 16px;  
                }
                &:not(.collapsed)::after{
                    // background-image:url("../../assets/images/ionic-ios-arrow-back.svg");
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.656' height='10.095' viewBox='0 0 17.656 10.095'%3E%3Cpath data-name='Icon ionic-ios-arrow-back' d='M7.052 8.826.371 2.15A1.262 1.262 0 0 1 2.158.369l7.569 7.563a1.259 1.259 0 0 1 .037 1.74l-7.6 7.616a1.262 1.262 0 0 1-1.788-1.782z' transform='rotate(90 8.828 8.828)' style='fill:%23666'/%3E%3C/svg%3E");
                    background-size: 13px; 
                  
                }
                &:not(.collapsed){
                    color: $default;
                    background-color: $white;
                    box-shadow: none;
                }
                &:focus{
                    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
                }
            }

        }
        .not-children{
            .accordion-button::after{
                content: none !important;
            }
        }
        .accordion-body{
            padding: 10px 0px 0px;
            .list-unstyled{
                margin-bottom: 0px;
                @media only screen and (max-width: 991.98px) {
margin-left: 35px;
                }
                li{
                    padding: 10px 0px;
                    a{
                     text-decoration: none; 
                     color: $default;  
                     &:hover, &:focus{
                        color: $primary;   
                     }
                    }
                }
            }
        }
    }
}
.gy-4 .col{
    padding-left: 16px;
    padding-right: 16px;
}
.fs-4 {
    font-size: 1.3rem!important;
}
.fs-5 {
    font-size: 1.2rem!important;
}
.fs-7{
    font-size: 1.063rem !important;
}
.fs-13{
    font-size: 0.813rem;
}
.cursor-pointer{
    cursor: pointer;
    transition: transform .2s ease-in-out;
}
.border-light{
    border-color: $light-border !important;
}
.transition{
    @include transition(.2s);
}
.rounded-1 {    
    border-radius: 0.25rem !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    font-weight: 700;
}
.accordion-item{
    background: transparent !important;
}
.modal-content{
    z-index: 0;
}
@media only screen and (max-width: 575.98px) {
 
    .fs-4 {
        font-size: 1.1rem!important;
    }
    .fs-5{
        font-size: 1.1rem !important;
    }
    // .fs-6 {
    //     font-size: 1rem!important;
    // }
    .fs-7{
        font-size: 1rem !important;
    }
}

.text-justify{
    text-align: justify;
}

.bg-primary{
    background-color: $primary !important;
    border: solid 1px $primary !important;
}

.opcity{
    opacity: 1 !important;
}
.hospital-icon{
    height: 16px;
    width: auto;
}

.flex-none{
    flex: none;
}
.w-80{
    width: 80%;
}
.w-20{
    width: 20%; 
    
}

.border-radius-right-0{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.border-radius-left-0{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.text-wrap{
    word-break: break-word;
}
.form-text-area{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $black;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $border-color;
    appearance: none;
    border-radius: 0.25rem;
    transition:  $border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}
.form-text-area:focus-visible {
    outline: $border-color auto 0px !important;
}